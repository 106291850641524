import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DeleteModal,
  InfoModal,
  AddOperator,
  AddArticle,
  DraftArticle,
  CreateCategory,
  UpdateOperator,
  ConfirmSend,
  CreateBotQuestion,
} from "../Modals";
import { closeModal } from "../../slices/modals";
import { useAuth } from "../../hooks";
import { resetMessagesStored } from "../../slices/messages";
import { resetConversationsStored } from "../../slices/conversation";

const ModalsProvider = () => {
  const { isAuthenticated } = useAuth();
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    return res[key];
  };
  const handleClose = (id) => {
    dispatch(closeModal(id));
    id === "update-operator" &&
      (() => {
        dispatch(resetMessagesStored());
        dispatch(resetConversationsStored());
      })();
  };
  return isAuthenticated ? (
    <>
      <InfoModal
        id="info-modal"
        open={modalState("info-modal", "open")}
        data={modalState("info-modal", "data")}
        handleClose={handleClose}
      />
      <DeleteModal
        id="delete-modal"
        open={modalState("delete-modal", "open")}
        data={modalState("delete-modal", "data")}
        handleClose={handleClose}
      />
      <AddOperator
        id="add-operator"
        open={modalState("add-operator", "open")}
        data={modalState("add-operator", "data")}
        handleClose={handleClose}
      />
      <UpdateOperator
        id="update-operator"
        open={modalState("update-operator", "open")}
        data={modalState("update-operator", "data")}
        handleClose={handleClose}
      />
      <AddArticle
        id="add-article"
        open={modalState("add-article", "open")}
        data={modalState("add-article", "data")}
        handleClose={handleClose}
      />
      <DraftArticle
        id="draft-article"
        open={modalState("draft-article", "open")}
        data={modalState("draft-article", "data")}
        handleClose={handleClose}
      />
      <CreateCategory
        id="create-category"
        open={modalState("create-category", "open")}
        data={modalState("create-category", "data")}
        handleClose={handleClose}
      />

      <ConfirmSend
        id="confirm-send"
        open={modalState("confirm-send", "open")}
        data={modalState("confirm-send", "data")}
        handleClose={handleClose}
      />
      <CreateBotQuestion
        id="create-bot-question"
        open={modalState("create-bot-question", "open")}
        data={modalState("create-bot-question", "data")}
        handleClose={handleClose}
      />
    </>
  ) : null;
};

export default ModalsProvider;
